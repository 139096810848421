import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "select",
    "fullImage",
    "categoryListings",
    "collectionsListingsField",
    "categoriesListingsField",
    "singleListing",
    "shared",
    "blockSettings",
    "sharedTitles",
    "smallImage",
    "bigImage",
    "textBlock",
    "videoBlock",
    "actions"
  ];

  toggle() {
    if (this.selectTarget.value === "full image") {
      this.fullImageTarget.style.display = "block";
      this.actionsTarget.style.display = "block";
      this.sharedTitlesTarget.style.display = "block";
      this.singleListingTarget.style.display = "none";
      this.categoryListingsTarget.style.display = "none";
      this.blockSettingsTarget.style.display = "block";
    } else if (this.selectTarget.value === "category listings") {
      this.fullImageTarget.style.display = "none";
      this.singleListingTarget.style.display = "none";
      this.categoryListingsTarget.style.display = "block";
      this.categoriesListingsFieldTarget.style.display = "block";
      this.collectionsListingsFieldTarget.style.display = "none";
      this.sharedTitlesTarget.style.display = "block";
      this.blockSettingsTarget.style.display = "block";
      this.actionsTarget.style.display = "block";
    } else if (this.selectTarget.value === "collection listings") {
      this.fullImageTarget.style.display = "none";
      this.singleListingTarget.style.display = "none";
      this.categoryListingsTarget.style.display = "block";
      this.categoriesListingsFieldTarget.style.display = "none";
      this.collectionsListingsFieldTarget.style.display = "block";
      this.sharedTitlesTarget.style.display = "block";
      this.blockSettingsTarget.style.display = "block";
      this.actionsTarget.style.display = "block";
    } 
    else if (this.selectTarget.value === "single listing") {
      this.singleListingTarget.style.display = "block";
      this.actionsTarget.style.display = "block";
      this.categoryListingsTarget.style.display = "none";
      this.blockSettingsTarget.style.display = "none";
      this.fullImageTarget.style.display = "none";
    } else if (this.selectTarget.value == 'general block') {
      this.actionsTarget.style.display = "block";
      this.sharedTitlesTarget.style.display = "block";
    } else if (this.selectTarget.value == 'text block') {
      this.sharedTitlesTarget.style.display = "block";
      this.textBlockTarget.style.display = "block";
      this.blockSettingsTarget.style.display = "block";
      this.actionsTarget.style.display = "block";
    } else if (this.selectTarget.value == 'video block') {
      this.videoBlockTarget.style.display = "block";
      this.actionsTarget.style.display = "block";
    } else {
      this.fullImageTarget.style.display = "none";
      this.categoryListingsTarget.style.display = "none";
      this.sharedTitlesTarget.style.display = "none";
      this.blockSettingsTarget.style.display = "none";
      this.actionsTarget.style.display = "none";
    }
  }
  showBigImage() {
    if (this.smallImageTarget.length == 0) {
      this.bigImageTarget.classList.remove("big-device")
    }
  }
  connect() { 
    this.toggle()
    this.showBigImage()
  }
}
