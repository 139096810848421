// app/javascript/controllers/load_more_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"]
  static values = { url: String, totalPages: Number }

  loadMore(event) {
    event.preventDefault()

    const url = this.urlValue
    fetch(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    })
    .then(response => response.text())
    .then(html => {
      const listingsContainer = document.getElementById("listings-container")
      listingsContainer.insertAdjacentHTML("beforeend", html)
      this.initializeNewInputs()

      // Update the button with the next page URL
      const nextPage = url.match(/page=(\d+)/)[1];
      const newPage = parseInt(nextPage) + 1;
      this.urlValue = url.replace(/page=\d+/, `page=${newPage}`);

      if (newPage > parseInt(this.totalPagesValue)) {
        this.buttonTarget.remove(); // Remove the button if no more pages
      }
    })
  }

  initializeNewInputs() {
    // Find all newly added inputs
    const newInputs = document.querySelectorAll("input")

    newInputs.forEach(input => {
      // Create and dispatch an input event for each new input
      const event = new Event('input', { bubbles: true })
      input.dispatchEvent(event)
    })
  }
}
