import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["categorySelect", "subCategorySelect"]

  connect() {
    this.updateSubCategories = this.updateSubCategories.bind(this);
  }

  async updateSubCategories() {
    const categoryId = this.categorySelectTarget.value;
    if (!categoryId) {
      this.subCategorySelectTarget.innerHTML = '<option value="">Select SubCategory</option>';
      return;
    }
    
    const data = await this.fetchSubcategories(categoryId);

    if (data.sub_categories.length > 0) {
        const options = [
            '<option value="">All SubCategories</option>',
            ...data.sub_categories.map(subCategory => 
                `<option value="${subCategory[0]}">${subCategory[1]}</option>`
              )
        ].join('');
        this.subCategorySelectTarget.innerHTML = options
    } else {
        this.subCategorySelectTarget.innerHTML = '<option value="">No SubCategories found</option>'
    }
  }

  async fetchSubcategories(categoryId) {
    const subId = this.subIdValue
    const response = await fetch(`/categories/${categoryId}/sub_categories?selected_sub_value=${subId}`);
    return response.json();
  }
}
